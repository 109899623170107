import React from 'react'

import {
  Box,
  Col,
  Columns,
  Main,
  Row,
  SkipLink,
  Wrapper
} from '@te-digi/styleguide'

import { HeaderExample } from './react/HeaderExamples'
import { FooterExample } from './react/FooterExample'

const ColumnsFullWidthExample = () => (
  <>
    <SkipLink />
    <Wrapper>
      <HeaderExample />
      <Columns layout="full-width">
        <Main>
          <Row gap="md">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(i => (
              <Col key={i}>
                <Box
                  border="none"
                  color="light"
                  noMargin
                  padding="none"
                >
                  <div style={{ height: '50vh' }} />
                </Box>
              </Col>
            ))}
          </Row>
        </Main>
      </Columns>
      <FooterExample />
    </Wrapper>
  </>
)

export default ColumnsFullWidthExample
