import React from 'react'

import { List, ListItem } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import { ZoomedExample } from '../../components/ZoomedExample'
import ColumnsDividedLeftSidebarExample from '../../examples/ColumnsDividedLeftSidebarExample'
import ColumnsDividedLeftSidebarFullExample from '../../examples/ColumnsDividedLeftSidebarFullExample'
import ColumnsFullWidthExample from '../../examples/ColumnsFullWidthExample'
import ColumnsSingleExample from '../../examples/ColumnsSingleExample'
import ColumnsWideExample from '../../examples/ColumnsWideExample'
import ColumnsWideSidebarExample from '../../examples/ColumnsWideSidebarExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content heading="Sivupohjat">
    <Section title="Yksittäinen palsta">
      <List variant="unordered">
        <ListItem>
          React: <Code>&lt;Columns layout="single"&gt;</Code>, HTML:{' '}
          <Code>.columns-single</Code>
        </ListItem>
        <ListItem>
          Artikkelit, uutiset, ym. sivut, joissa ei käytetä sivupalkkia
          alanavigaatiolle.
        </ListItem>
      </List>
      <ZoomedExample>
        <ColumnsSingleExample />
      </ZoomedExample>
    </Section>
    <Section title="Yksittäinen palsta, leveä">
      <List variant="unordered">
        <ListItem>
          React: <Code>&lt;Columns layout="wide"&gt;</Code>, HTML:{' '}
          <Code>.columns-wide</Code>
        </ListItem>
        <ListItem>
          Sivut, joissa ei käytetä sivupalkkia alanavigaatiolle.
        </ListItem>
        <ListItem>
          Sivut, joilla tarvitaan enemmän tilaa näytöltä, esim. usealle
          vierekkäiselle kortille tai kustomoiduille palstoituksille.
        </ListItem>
        <ListItem>
          Sivupohjassa voi käyttää <Code>Main</Code>-komponentin sisässä{' '}
          <Code>ColumnsInner</Code>-komponenttia, jolloin sivupohja käyttäytyy
          kuten kapeampi palstainen.
        </ListItem>
      </List>
      <ZoomedExample>
        <ColumnsWideExample />
      </ZoomedExample>
    </Section>
    <Section title="Sivupalkilla">
      <List variant="unordered">
        <ListItem>
          React: <Code>&lt;Columns layout="divided-left-sidebar"&gt;</Code> /{' '}
          <Code>&lt;Columns layout="sidebar"&gt;</Code>, HTML:{' '}
          <Code>.columns-divided-left-sidebar</Code> /{' '}
          <Code>.columns-sidebar</Code>
        </ListItem>
        <ListItem>Sivut, joissa käytetään sivupalkkia.</ListItem>
        <ListItem>
          Sivupohjasta on kaksi variaatiota; toisessa sivupalkki näytetään
          tablet-koossa ja siitä ylöspäin. Toisessa sivupalkki on näkyvissä myös
          mobiili-koossa pääpalstan yläpuolella (<Code>columns-sidebar</Code>).
        </ListItem>
      </List>
      <ZoomedExample>
        <ColumnsDividedLeftSidebarExample />
      </ZoomedExample>
    </Section>
    <Section title="Sivupalkilla, leveä">
      <List variant="unordered">
        <ListItem>
          React: <Code>&lt;Columns layout="divided-left-sidebar-full"&gt;</Code>{' '}
          / <Code>&lt;Columns layout="sidebar-full"&gt;</Code>, HTML:{' '}
          <Code>.columns-divided-left-sidebar-full</Code> /{' '}
          <Code>.columns-sidebar-full</Code>
        </ListItem>
        <ListItem>Sivut, joissa käytetään sivupalkkia.</ListItem>
        <ListItem>
          Sivut, joilla tarvitaan enemmän tilaa näytöltä, esim. usealle
          vierekkäiselle kortille tai kustomoiduille palstoituksille.
        </ListItem>
        <ListItem>
          Sivupohjasta on kaksi variaatiota; toisessa sivupalkki näytetään
          tablet-koossa ja siitä ylöspäin. Toisessa sivupalkki on näkyvissä myös
          mobiili-koossa pääpalstan yläpuolella (
          <Code>columns-sidebar-full</Code>).
        </ListItem>
        <ListItem>
          Sivupohjassa voi käyttää <Code>Main</Code>-komponentin sisässä{' '}
          <Code>ColumnsInner</Code>-komponenttia, jolloin sivupohja käyttäytyy
          kuten kapeampi palstainen.
        </ListItem>
      </List>
      <ZoomedExample>
        <ColumnsDividedLeftSidebarFullExample />
      </ZoomedExample>
    </Section>
    <Section title="Koko leveys">
      <List variant="unordered">
        <ListItem>
          React: <Code>&lt;Columns layout"full-width"&gt;</Code>, HTML:{' '}
          <Code>.columns-full-width</Code>
        </ListItem>
      </List>
      <ZoomedExample>
        <ColumnsFullWidthExample />
      </ZoomedExample>
    </Section>
    <Section title="Leveä sivupalkki">
      <List variant="unordered">
        <ListItem>
          React: <Code>&lt;Columns layout="wide-sidebar"&gt;</Code>, HTML:{' '}
          <Code>.columns-wide-sidebar</Code>
        </ListItem>
        <ListItem>
          Sivut, joilla tarvitaan enemmän tilaa näytöltä, esim. työkalumaiset
          web-sovellukset.
        </ListItem>
        <ListItem>
          Sivut, joissa tarvitaan tavallista leveämpää sivupalkkia.
        </ListItem>
      </List>
      <ZoomedExample>
        <ColumnsWideSidebarExample />
      </ZoomedExample>
    </Section>
  </Content>
)

export default Page
